import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import SEO from '../components/SEO';

const Err404: React.FC = () => (
  <>
    <SEO title="Stran ne obstaja!" />
    <Container>
      <Text>Ta stran ne obstaja!</Text>
      <Btn to="/">Domov</Btn>
    </Container>
  </>
);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
`;

const Text = styled.h2``;

const Btn = styled(Link)`
  cursor: pointer;
  margin-top: 1rem;
  padding: 0.6rem 2rem;
  font-size: 1rem;
  background: var(--rdeca);
  color: #fff;
  text-decoration: none;

  &:hover {
    filter: brightness(1.1);
  }
`;

export default Err404;
